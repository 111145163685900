<template>
    <TransitionSlide appear :offset="{ enter: [0, '-10%'], leave: [0, '25%'] }">
        <div v-if="!hasResponded"
             class="visitor-font fixed bottom-0 z-50 flex flex-wrap items-center justify-evenly gap-4 rounded border
                    border-base-300/60 bg-base-200 px-6 py-4 shadow-xl sm:flex-nowrap md:bottom-[2%] md:right-[2%]
                    md:max-w-2xl">
            <Icon name="alertCircle" class="text-primary/80" />
            <p>
                This website uses cookies to enhance the user experience. We will assume you are ok with this, but you
                can opt-out if you wish.
                <br>
                <NuxtLink :to="{ name: 'cookie-policy' }">
                    Click here to read about the Cookie Policy.
                </NuxtLink>
            </p>
            <div class="grid w-full grid-cols-1 items-center gap-2 sm:grid-cols-2">
                <button class="btn no-animation text-xs md:text-sm"
                        @click="setConsent(false)">
                    Reject
                </button>
                <button class="btn btn-primary order-first text-xs sm:order-none md:text-base"
                        @click="setConsent(true)">
                    Accept
                </button>
                <button class="btn btn-ghost no-animation btn-sm col-span-full text-xs
                               sm:justify-self-center md:text-sm"
                        @click="preferences?.showModal()">
                    Preferences
                </button>
            </div>
        </div>
    </TransitionSlide>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import useConsent from '~/composables/useConsent';

export default defineComponent({
    name: 'CookieConsent',

    setup: () => {
        const { preferences, setConsent, hasResponded } = useConsent();

        return {
            preferences,
            setConsent,
            hasResponded
        };
    }
});
</script>
